<template>
  <main>
    <div v-if="uncheckModal" class="uncheck-modal">
      <div>
        <div class="bold">Uh Oh!</div>
        <div class="exlpanation">
          We weren’t able to retrieve your role. Please select your position to
          continue:
        </div>
        <div class="inputs">
          <input v-model="role" value="AISE" name="role" type="radio" />Exterior
          Tech
          <input
            class="input"
            v-model="role"
            value="AISI"
            name="role"
            type="radio"
          />Interior Tech
        </div>
      </div>
      <div class="button-area">
        <div class="submit" @click="handleSubmit()">Next</div>
      </div>
    </div>
    <BlueprintBlackLogo class="logo" />
    <section class="main-container">
      <p class="welcome-text">
        Welcome Home, {{ store.state.user.first_name }}!
      </p>
      <h2 class="header-2">Select a house to build</h2>
      <p class="desc-text">
        At The Home Depot Home Services, we’re all about helping our customers
        make a house a home! To get you ready for your new role, you’ll complete
        the Onboarding Journey from the blueprint to finishing touches,
        constructing the virtual house of your choice along the way. Let’s get
        started!
      </p>
      <section class="houses">
        <div
          v-for="house in houses"
          :key="house.id"
          class="house-block"
          :class="selectedHouse === house.id ? 'selected' : ''"
          @click="changeSelected(house.id)"
        >
          <img :src="house.src" alt="house" />
        </div>
      </section>
      <div class="modal-area">
        <div class="modal" v-if="showModal">
          Please select a house to continue!
        </div>
      </div>
      <SimpleButton
        v-if="!uncheckModal"
        label="Next"
        style-class="next-button"
        @click="confirmHouse"
      />
    </section>
  </main>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import BlueprintBlackLogo from "@/components/ui/BlueprintBlackLogo";
import house1 from "@/assets/images/houses/House 1.svg";
import house2 from "@/assets/images/houses/house 2.svg";
import SimpleButton from "@/components/ui/SimpleButton";
import UserAPI from "@/api/UserAPI";
import { userNames } from "@/store/modules/user";
import router from "@/router";
const role = ref(null);
const uncheckModal = ref(false);
const store = useStore();
onMounted(async () => {
  const user = store.state.user;
  role.value = user?.role;
  selectedHouse.value = user?.home_style;
  if (user.role === "AIS") {
    uncheckModal.value = true;
  }
});
const showContinue = ref(false);
const handleSubmit = async () => {
  "roll:", role;
  if (role.value) {
    const user = store.state.user;
    user.role = role.value;
    await store.commit(userNames.setUser, user);
    UserAPI.updateMyUser(user);
    uncheckModal.value = false;
    showContinue.value = true;
  }
};
const houses = [
  {
    src: house1,
    id: 1,
  },
  {
    src: house2,
    id: 2,
  },
];

const selectedHouse = ref();
const showModal = ref(false);
const changeSelected = (id) => {
  if (!uncheckModal.value) {
    showModal.value = false;
    selectedHouse.value = id;
  }
};

const confirmHouse = async () => {
  const user = store.state.user;
  user.home_style = selectedHouse.value;

  if (!selectedHouse.value) {
    showModal.value = true;
    return;
  }
  try {
    await store.commit(userNames.setUser, user);
    await UserAPI.updateMyHomeStyle(selectedHouse.value);
    // maybe rewrite this logic
    if (!store.state.user?.avatar?.base) {
      await router.push("/personalize/avatar-builder");
    } else {
      await router.push("/");
    }
  } catch (error) {
    error;
  }
};
</script>

<style scoped>
.main {
  justify-content: center;
  align-items: center;
}
.uncheck-modal {
  width: 470px;
  height: 350px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  position: fixed;
  left: 33%;
  flex-direction: column;
  padding: 20px 60px 20px 60px;
  bottom: 40%;
  z-index: 1000;
  border: solid 1px black;
  /* transform: translate(-50%, -50%); */
}
.submit {
  background: #f96302;
  border-radius: 30px;
  width: 165.48px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.input {
  margin-left: 25px;
}
.inputs {
  justify-content: space-between;
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 381%;
  margin-top: 10px;
  align-items: center;

  color: #5f6562;
}
.bold {
  font-family: "Helvetica Neue LT Std";
  font-style: normal;
  font-weight: 750;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 20px;
  color: #000000;
}
.logo {
  margin-top: 20px;
  height: 141px;
  margin-left: 100px;
  width: 215px;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 10px; */
}

.modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  color: white;
  width: 249px;
  height: 30px;
  /* Alert */
  background: #b32d22;
  border-radius: 3px;
}
.modal-area {
  margin-bottom: 15px;
  height: 30px;
}

.welcome-text {
  text-transform: uppercase;
  color: #ff8217;
}

.header-2 {
  margin: 0;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 36px;
  color: black;
}

.desc-text {
  width: 503px;
  text-align: center;
}

.houses {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 100px;
  /* margin: 50px 50px 0; */
}

.house-block {
  padding: 10px;
  cursor: pointer;
}

.selected {
  background: white;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.13);
  border-radius: 30px;
  transition: box-shadow, background-color 0.3s ease;
}

.next-button {
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 0 70px;
}
</style>
